import React from "react";
import SearchResultPage from "../../../templates/search-results-template";

const SearchResultCommercialLettings = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location

    return (
        <React.Fragment>
            <SearchResultPage 
                locationname="/commercial-property/for-rent/" 
                pcategorytype="commercial" 
                ptype="lettings" 
                ptypetag="for-rent" 
                pstatustype="to let"
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
                psearch="Lettings"
            />
        </React.Fragment>
    );
};

export default SearchResultCommercialLettings;